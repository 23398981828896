import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import numeral from 'numeral'
import Typography from '@mui/material/Typography'
import moment from 'moment-timezone'
import Stack from '@mui/material/Stack'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import { IBookingCardProps } from './types'
import { calculateDateToRender } from '../../utils/date'
import { theme } from '../../styles/mui-theme'
import BookingForm from '../BookingForm'
import { IAppointmentBooking } from '../../types/appointments'
import { IBookingValues } from '../BookingForm/types'
import GridSpinner from '../GridSpinner'

const BookingCard: React.FC<IBookingCardProps> = ({
  bookings,
  portTimeZone,
  appointmentId,
  products,
  vessels,
  onEditBooking,
  isLoading,
  haveCards,
  other_guests,
}) => {
  const [isOpenBookingForm, setIsOpenBookingForm] = useState<boolean>(false)
  const [bookingToEdit, setBookingToEdit] = useState<IBookingValues>()

  const onOpenFormBooking = (booking: IAppointmentBooking) => {
    setIsOpenBookingForm(true)
    if (booking) {
      setBookingToEdit({
        appointmentId: booking.appointment_id,
        bookingId: booking.booking_id,
        productId: booking?.destination?.id ? booking?.destination?.id.toString() : '',
        vesselId: booking?.ship?.id ? booking?.ship?.id.toString() : '',
        status: booking.booking_status?.toLowerCase() || '',
        bookingOrigin: booking.booking_origin || '',
        bookingNumber: booking.booking_number?.toString() || '',
        numberOfBookings: '',
        groupShellNumber: '',
        sailDate: booking.booking_saildate || '',
        stateRoomCategory: booking.booking_stateroom_category || '',
        amount: booking.booking_amount?.toString() || '',
        nonRefutable: !!booking.booking_non_refundable,
        usedRCVisaCard: !!booking.booking_used_visa_card,
        pendingItems: !!booking.booking_pending_items,
        enableGroup: false,
        comment: booking.booking_comment?.trim() || '',
        bookingName: booking.booking_name || '',
        travelAgencyChange: booking.booking_travel_agency,
        casinoBooking: booking.booking_casino,
        reasonWhy: booking.booking_reasonWhy,
        groupName: booking.group_name,
        reasonBookingCancelled: booking.reason_booking_cancelled,
        bookingType: booking.booking_type || 'Book Now',
        bookingBrand: booking.booking_brand,
        guestCategory: booking.guest_category,
        depositAmount: booking.deposit_amount,
        depositCurrency: booking.deposit_currency,
        guest: booking.guest,
      })
    }
  }

  const onSaveBookingToEdit = (booking: IBookingValues) => {
    onEditBooking(booking)
    setIsOpenBookingForm(false)
  }

  if (isLoading) return <GridSpinner />

  return bookings.length === 0 ? (
    <Alert severity="info">No bookings for this appointment</Alert>
  ) : (
    <Paper elevation={3} style={{ marginBottom: 2, maxHeight: 240, overflow: 'auto' }}>
      {bookings.map((booking) => (
        <Card
          key={booking.booking_id}
          sx={{
            mb: 2,
            ml: 1,
            mr: 1,
            mt: 1,
            backgroundColor:
              appointmentId === booking.appointment_id
                ? theme.palette.custom.backgroundGrey
                : theme.palette.custom.backgroundBlue,
          }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="center" alignItems="center" height={1}>
                  <Typography>{booking.booking_status}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={8} pl={0}>
                {(booking.booking_type === 'Book Now' || booking.booking_type === '') && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>
                        <Typography component="strong" fontWeight="bold" fontSize={14}>
                          Book Now
                        </Typography>
                      </Typography>
                      <Typography>
                        <Typography component="strong" fontWeight="bold" fontSize={14}>
                          Brand:
                        </Typography>{' '}
                        {booking.booking_brand ? booking.booking_brand : ''}
                      </Typography>
                      {booking.destination && booking.destination.id && (
                        <Typography>
                          <Typography component="strong" fontWeight="bold" fontSize={14}>
                            Product:
                          </Typography>{' '}
                          {booking.destination.name ? booking.destination.name : ''}
                        </Typography>
                      )}
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Booking #:
                        </Typography>{' '}
                        {booking.booking_number}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Group Shell Number #:
                        </Typography>{' '}
                        {booking.group_shell_number}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Agent:
                        </Typography>{' '}
                        {`${booking.agent?.first_name} ${booking.agent?.last_name}`}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Last update:
                        </Typography>{' '}
                        {calculateDateToRender(portTimeZone, booking.booking_status_date!)}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Non-refundable:
                        </Typography>{' '}
                        {booking.booking_non_refundable ? 'Yes' : 'No'}
                      </Typography>
                      {booking.booking_comment && booking.booking_comment !== '' && (
                        <Typography>
                          <Typography fontWeight="bold" component="strong" fontSize={14}>
                            Comment:
                          </Typography>{' '}
                          {booking.booking_comment}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {booking.guest?.person_id && (
                        <Typography>
                          <Typography component="strong" fontWeight="bold" fontSize={14}>
                            Guest:
                          </Typography>{' '}
                          {`${booking.guest?.first_name} ${booking.guest?.last_name}`}
                        </Typography>
                      )}
                      <Typography>
                        <Typography component="strong" fontWeight="bold" fontSize={14}>
                          Guest Category:
                        </Typography>{' '}
                        {booking.guest_category ? booking.guest_category : ''}
                      </Typography>
                      <Typography>
                        <Typography component="strong" fontWeight="bold" fontSize={14}>
                          Brand:
                        </Typography>{' '}
                        {booking.booking_brand ? booking.booking_brand : ''}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Vessel:
                        </Typography>{' '}
                        {booking.ship?.name}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Amount:
                        </Typography>{' '}
                        {booking.booking_amount
                          ? `$ ${numeral(booking.booking_amount).format('0,0.00')}`
                          : 'N/A'}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Sail Date:
                        </Typography>{' '}
                        {moment(booking.booking_saildate).isValid()
                          ? moment.utc(booking.booking_saildate).format('MMM Do, YYYY')
                          : 'N/A'}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Category:
                        </Typography>{' '}
                        {booking.booking_stateroom_category || 'N/A'}
                      </Typography>

                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Pending items:
                        </Typography>{' '}
                        {booking.booking_pending_items ? 'Yes' : 'No'}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Used Celebrity Credit Card:
                        </Typography>{' '}
                        {booking.booking_used_visa_card ? 'Yes' : 'No'}
                      </Typography>
                      {booking.booking_name && (
                        <Typography>
                          <Typography fontWeight="bold" component="strong" fontSize={14}>
                            MCC Ref:{' '}
                          </Typography>
                          {booking.booking_name ? booking.booking_name : 'N/A'}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
                {booking.booking_type === 'Book Later' && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>
                        <Typography component="strong" fontWeight="bold" fontSize={14}>
                          Book Later
                        </Typography>
                      </Typography>
                      <Typography>
                        <Typography component="strong" fontWeight="bold" fontSize={14}>
                          Brand:
                        </Typography>{' '}
                        {booking.booking_brand ? booking.booking_brand : ''}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Origin:
                        </Typography>{' '}
                        {booking.booking_origin || 'N/A'}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Booking #:
                        </Typography>{' '}
                        {booking.booking_number}
                      </Typography>
                      {booking.booking_comment && booking.booking_comment !== '' && (
                        <Typography>
                          <Typography fontWeight="bold" component="strong" fontSize={14}>
                            Comment:
                          </Typography>{' '}
                          {booking.booking_comment}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {booking.guest?.person_id && (
                        <Typography>
                          <Typography component="strong" fontWeight="bold" fontSize={14}>
                            Guest:
                          </Typography>{' '}
                          {`${booking.guest?.first_name} ${booking.guest?.last_name}`}
                        </Typography>
                      )}
                      <Typography>
                        <Typography component="strong" fontWeight="bold" fontSize={14}>
                          Guest Category:
                        </Typography>{' '}
                        {booking.guest_category ? booking.guest_category : ''}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Agent:
                        </Typography>{' '}
                        {`${booking.agent?.first_name} ${booking.agent?.last_name}`}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Last update:
                        </Typography>{' '}
                        {calculateDateToRender(portTimeZone, booking.booking_status_date!)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {booking.booking_type === 'App Booking' && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>
                        <Typography component="strong" fontWeight="bold" fontSize={14}>
                          App Booking
                        </Typography>
                      </Typography>
                      <Typography>
                        <Typography component="strong" fontWeight="bold" fontSize={14}>
                          Brand:
                        </Typography>{' '}
                        {booking.booking_brand ? booking.booking_brand : ''}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Origin:
                        </Typography>{' '}
                        {booking.booking_origin || 'N/A'}
                      </Typography>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Booking #:
                        </Typography>{' '}
                        {booking.booking_number && booking.booking_number !== '0'
                          ? booking.booking_number
                          : 'Not assigned yet'}
                      </Typography>
                      {booking.booking_comment && booking.booking_comment !== '' && (
                        <Typography>
                          <Typography fontWeight="bold" component="strong" fontSize={14}>
                            Comment:
                          </Typography>{' '}
                          {booking.booking_comment}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {booking.guest?.person_id && (
                        <Typography>
                          <Typography component="strong" fontWeight="bold" fontSize={14}>
                            Guest:
                          </Typography>{' '}
                          {`${booking.guest?.first_name} ${booking.guest?.last_name}`}
                        </Typography>
                      )}
                      {booking.guest_category && (
                        <Typography>
                          <Typography component="strong" fontWeight="bold" fontSize={14}>
                            Guest Category:
                          </Typography>{' '}
                          {booking.guest_category}
                        </Typography>
                      )}
                      {booking.agent?.first_name && booking.agent?.last_name && (
                        <Typography>
                          <Typography fontWeight="bold" component="strong" fontSize={14}>
                            Agent:
                          </Typography>{' '}
                          {`${booking.agent?.first_name} ${booking.agent?.last_name}`}
                        </Typography>
                      )}
                      {booking.booking_status_date && (
                        <Typography>
                          <Typography fontWeight="bold" component="strong" fontSize={14}>
                            Last update:
                          </Typography>{' '}
                          {calculateDateToRender(portTimeZone, booking.booking_status_date!)}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="center" alignItems="center" height={1}>
                  <IconButton onClick={() => onOpenFormBooking(booking)}>
                    <EditIcon />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      {isOpenBookingForm && (
        <BookingForm
          type={bookingToEdit ? bookingToEdit.bookingType || 'Book Now' : 'Book Now'}
          other_guests={other_guests}
          isOpen={isOpenBookingForm}
          onAccept={onSaveBookingToEdit}
          onClose={() => setIsOpenBookingForm(false)}
          products={products || []}
          vessels={vessels || []}
          booking={bookingToEdit}
          haveCards={haveCards}
        />
      )}
    </Paper>
  )
}

export default BookingCard
