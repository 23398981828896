import { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Alert,
  Box,
  Button,
  Chip,
  Fab,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import SummarizeIcon from '@mui/icons-material/Summarize'
import DeleteIcon from '@mui/icons-material/Delete'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FaceIcon from '@mui/icons-material/Face'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { useVoyages } from './hook'
import { IGetCruisesResponse } from './types'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import SettingsLayout from '../../../components/SettingsLayout'
import GridSpinner from '../../../components/GridSpinner'
import ConfirmDialog from '../../../components/ConfirmDialog'
import AgentsForm from '../../../components/AgentsForm'
import AgentsDailyGoalForm from '../../../components/AgentsDailyGoalForm'
import { headTableStyle } from '../../../styles/generic-styles'
import LoadingSwitch from '../../../components/LoadingSwitch'
import { EUserRole, IAgentPayload, ICruiseAgent, IDeleteAgentPayload } from '../../../types/users'
import { useGetWizardQuery } from '../../../redux/api/utils'
import { setWizard } from '../../../redux/features/auth'
import {
  IAgentsGoalFormData,
  IAgentsGoalPayload,
} from '../../../components/AgentsDailyGoalForm/types'

const SettingsVoyages = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)
  const { data, refetch: refetchWizard } = useGetWizardQuery()

  const {
    refetch,
    cruisesData,
    isLoading,
    allUsers,
    assignedAgents,
    isAssignAgentLoading,
    isAssignAgentGoalLoading,
    isLoadingAssignedAgents,
    isLoadingActiveInactiveCruise,
    isLoadingUnassignAgent,
    remove,
    onActiveChange,
    getAssignedAgentsTrigger,
    assignAgent,
    assignAgentGoal,
    removeAgent,
  } = useVoyages()

  const [listNeedsRefresh, setListNeedsRefresh] = useState<boolean>(false)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)
  const [isAgentsFormOpen, setIsAgentsFormOpen] = useState<boolean>(false)
  const [selectedCruise, setSelectedCruise] = useState<IGetCruisesResponse | undefined>()

  // Agent Daily Goal
  const [formData, setFormData] = useState<IAgentsGoalFormData>({
    agentGoals: [],
  })
  const [selectedAgent, setSelectedAgent] = useState<ICruiseAgent | undefined>()
  const [agentGoalOpen, setAgentGoalOpen] = useState<boolean>(false)

  useEffect(() => {
    setFormData({
      agentGoals: selectedAgent ? selectedAgent.goals : [],
    })
  }, [selectedAgent])

  useEffect(() => {
    dispatch(setWizard(data))
  }, [data])

  const onUnassignAgent = (payload: IDeleteAgentPayload) => {
    removeAgent(payload, async () => {
      setListNeedsRefresh(true)
      refetchWizard()
      if (selectedCruise) await getAssignedAgentsTrigger(selectedCruise.cruise_id)
    })
  }

  const actionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    cruise: IGetCruisesResponse,
  ) => {
    setSelectedCruise(cruise)
    onActiveChange(
      { action: event.target.checked ? 'active' : 'inactive', cruiseId: cruise.cruise_id },
      () => {
        refetchWizard()
      },
    )
  }

  const selectCruiseToDelete = (cruise: IGetCruisesResponse): void => {
    setSelectedCruise(cruise)
    setIsConfirmDialogOpen(true)
  }

  const onRemovalSuccess = (): void => {
    setIsConfirmDialogOpen(false)
    refetch()
    refetchWizard()
  }

  const selectCruise = async (cruise: IGetCruisesResponse): Promise<void> => {
    setSelectedCruise(cruise)
    setIsAgentsFormOpen(true)
    await getAssignedAgentsTrigger(cruise.cruise_id)
  }

  const onCloseAgentsForm = () => {
    setIsAgentsFormOpen(false)
    if (listNeedsRefresh) {
      refetch()
      setListNeedsRefresh(false)
    }
  }

  const onCloseAgentsDailyGoalForm = () => {
    setSelectedAgent(undefined)
    setAgentGoalOpen(false)
  }

  const onAddAgent = async (agent: IAgentPayload): Promise<void> => {
    if (!selectedCruise) return

    await assignAgent(
      {
        ...agent,
        cruiseId: selectedCruise.cruise_id,
      },
      async () => {
        refetchWizard()
        setListNeedsRefresh(true)
        if (selectedCruise) await getAssignedAgentsTrigger(selectedCruise.cruise_id)
      },
    )
  }

  const selectAgent = (agent: ICruiseAgent) => {
    setSelectedAgent(agent)
    setAgentGoalOpen(true)
  }

  const addAgentGoals = async (agentGoalFormData: IAgentsGoalFormData) => {
    const agentGoalPayload: IAgentsGoalPayload = {
      agent_id: selectedAgent!.id,
      cruise_id: selectedCruise!.cruise_id,
      goals: agentGoalFormData.agentGoals.map((item) => ({
        agent_port_id: item.agent_port_id || undefined,
        arrival_date: item.arrival_date,
        port_id: item.port_id,
        goal: item.goal || 0,
      })),
    }

    await assignAgentGoal(agentGoalPayload, async () => {
      if (selectedCruise) await getAssignedAgentsTrigger(selectedCruise.cruise_id)
      setSelectedAgent(undefined)
      setAgentGoalOpen(false)
    })
  }

  return (
    <SettingsLayout>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
        <Box>
          <Button
            variant="contained"
            color="success"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => navigate('/settings/voyages/new')}>
            New Voyage
          </Button>
        </Box>
        <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
          Voyages Management
        </Typography>
      </Stack>

      <TableContainer
        sx={{ maxHeight: 'calc(100vh - 340px)', backgroundColor: 'primary.contrastText' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={headTableStyle}>VOYAGE NAME</TableCell>
              <TableCell sx={headTableStyle}>NUMBER</TableCell>
              <TableCell sx={headTableStyle}>VESSEL</TableCell>
              <TableCell sx={headTableStyle}>PRODUCT & DATES</TableCell>
              <TableCell
                align="center"
                sx={{
                  ...headTableStyle,
                  minWidth: 100,
                  width: 100,
                }}>
                ACTIVE
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  ...headTableStyle,
                  minWidth: 160,
                  width: 160,
                }}>
                AGENTS
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  ...headTableStyle,
                  minWidth: 180,
                  width: 180,
                  zIndex: 1051,
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              cruisesData?.map((cruise: IGetCruisesResponse, index: number) => (
                <TableRow key={`table-row-cruise-${index}`}>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={`/settings/voyages/${cruise.cruise_id}`}
                      variant="body1">
                      {cruise.description}
                    </Link>
                  </TableCell>
                  <TableCell>{cruise.voyage_number}</TableCell>
                  <TableCell>{cruise.ship.name}</TableCell>
                  <TableCell>
                    <strong>{cruise.destination.name}</strong>
                    <br />
                    {moment.utc(cruise.start_date).format('MM/DD/YYYY')} -{' '}
                    {moment.utc(cruise.end_date).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell align="center">
                    <LoadingSwitch
                      isLoading={
                        selectedCruise?.cruise_id === cruise.cruise_id &&
                        isLoadingActiveInactiveCruise
                      }
                      isChecked={cruise.active === 1}
                      isDisabled={
                        selectedCruise?.cruise_id !== cruise.cruise_id &&
                        isLoadingActiveInactiveCruise
                      }
                      onChange={(event) => actionChange(event, cruise)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Assign or remove agents" sx={{ mr: 1 }}>
                      {cruise.agents_count.length > 0 ? (
                        <Chip
                          icon={<FaceIcon />}
                          label={`${cruise.agents_count.length} Agent${
                            cruise.agents_count.length === 1 ? '' : 's'
                          }`}
                          color="primary"
                          sx={{ width: (theme) => `${theme.spacing(17)}` }}
                          onClick={() => selectCruise(cruise)}
                        />
                      ) : (
                        <Chip
                          icon={<FaceIcon />}
                          label="Assign Agents"
                          color="secondary"
                          sx={{ width: (theme) => `${theme.spacing(17)}` }}
                          onClick={() => selectCruise(cruise)}
                        />
                      )}
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="View Credit Card Report" sx={{ mr: 1 }}>
                      <Fab
                        color="secondary"
                        size="small"
                        onClick={() =>
                          navigate(`/settings/voyages/${cruise.cruise_id}/cardReport`)
                        }>
                        <CreditScoreIcon />
                      </Fab>
                    </Tooltip>
                    <Tooltip title="View Voyage Report" sx={{ mr: 1 }}>
                      <Fab
                        color="secondary"
                        size="small"
                        onClick={() => navigate(`/settings/voyages/${cruise.cruise_id}/report`)}>
                        <SummarizeIcon />
                      </Fab>
                    </Tooltip>
                    {loggedUser?.role === EUserRole.SUPER_ADMIN && (
                      <Tooltip title="Delete Voyage">
                        <Fab
                          color="error"
                          size="small"
                          onClick={() => selectCruiseToDelete(cruise)}>
                          <DeleteIcon />
                        </Fab>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && <GridSpinner />}

      <Alert sx={{ width: '100%', mt: 4 }} severity="info">
        Voyages older than 90 days ({moment().add(-90, 'days').format('MMMM D, YYYY')}) are not
        displayed. Please contact your manager for questions
      </Alert>

      <ConfirmDialog
        title="Are you sure you want to remove this voyage?"
        message="Notice this action cannot be undone."
        onAccept={async () =>
          remove(selectedCruise!.cruise_id, onRemovalSuccess, () => setIsConfirmDialogOpen(false))
        }
        onReject={() => setIsConfirmDialogOpen(false)}
        isOpen={isConfirmDialogOpen}
      />

      {selectedCruise && (
        <AgentsForm
          isOpen={isAgentsFormOpen}
          cruise={selectedCruise}
          onClose={onCloseAgentsForm}
          assignedAgents={assignedAgents}
          allUsers={allUsers}
          selectAgent={selectAgent}
          onSubmit={onAddAgent}
          isLoading={isAssignAgentLoading}
          isLoadingGetCruiseAgents={isLoadingAssignedAgents}
          isLoadingDeleteAgent={isLoadingUnassignAgent}
          onDelete={onUnassignAgent}
        />
      )}

      {selectedAgent && (
        <AgentsDailyGoalForm
          isOpen={agentGoalOpen}
          agent={selectedAgent}
          goals={formData.agentGoals}
          isLoading={isAssignAgentGoalLoading}
          onSubmit={addAgentGoals}
          onClose={onCloseAgentsDailyGoalForm}
        />
      )}
    </SettingsLayout>
  )
}

export default SettingsVoyages
